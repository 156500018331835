import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Pagination } from 'app/core/api/pagination.type';
import { Department } from './department.model';

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {
    private _departments: BehaviorSubject<Department[] | null> = new BehaviorSubject(null);
    private _hasMore: BehaviorSubject<Boolean> = new BehaviorSubject(null);

    errorMessage: string = "Something went wrong ";
    pagination: Pagination;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    get departments$(): Observable<Department[]> {
        return this._departments.asObservable();
    }
    get hasMoreItems$(): Observable<Boolean> {
        return this._hasMore.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getDepartmentsInitial(): Observable<any> {
        return this.getDepartments(null, null, null, null, null)
    }

    getDepartments(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<any> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10
        }
        return this._httpClient.get(`${BASE_URL}organization/department/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                this._departments.next(response.data);
                if (response.data.length == 0)
                    this.errorMessage = "There are no departments to display!"
            })
        );
    }

    deleteDepartment(departmentId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}organization/department/${departmentId}/`).pipe(
            tap((res: any) => {
                // this.pagination?.total_count -= 1
                this._departments.next(this._departments.value.filter(item => item.id !== departmentId));
            })
        )
    }

    createDepartment(credentials): Observable<any> {
        let params = null
        return this._httpClient.post(`${BASE_URL}organization/department/`, credentials, {
            params: params
        });
    }

    updateDepartment(departmentId: number, credentials): Observable<any> {
        let params = null
        return this._httpClient.put(`${BASE_URL}organization/department/${departmentId}/`, credentials, {
            params: params
        });
    }

}
